<template>
  <div>
    <h1>Search</h1>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchKey: null,
    };
  },
  mounted() {
    if (
      this.$route.query.keyword === undefined ||
      this.$route.query.keyword.length === 0
    ) {
      this.$router.push({ name: "Index" });
    }
    this.searchKey = this.$route.query.keyword;
    document.title = this.searchKey + " - 搜索结果 - PRPRU";
  },

};
</script>

<style>
</style>